import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clientConversationStored as clientConversationStoredFunction,
  getAllConversations,
  getAllConversationsByDate,
  getAllConversationsByDepartment,
  getAllConversationsByFile,
  getAllConversationsByMeta,
  getAllConversationsByMinutes,
  getAllConversationsByOperator,
  getConvByClient,
  getFilteredConversations,
  getOneConversationByClient,
  incrementPageForConversations,
} from "../../../slices/conversation";
import { conversationMessages, storeMessages } from "../../../slices/messages";
import { isFound } from "../../../utilities/isFound";
import ConversationCard from "./ConversationCard";
import data from "../../../utilities/constants";
import { Skeleton } from "@mui/material";
import { emitEvent } from "../../../socket/socket";
import { useLocation } from "react-router-dom";
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { useTranslation } from "react-i18next";
import {
  getArchivedMessages,
  storeArchivedMessages,
} from "../../../slices/archivedMessages";
import {
  getArchivedConversations,
  getOneArchivedConversation,
  incrementPageForArchivedConversations,
  storeArchivedConversation,
} from "../../../slices/archivedConversation";

const Conversations = ({
  user,
  choosenSegment,
  assignedConversations,
  setAssignedConversations,
  archived,
}) => {
  const [activeConversationCard, setActiveConversationCard] = useState(false);
  const [activeArchivedConversation, setActiveArchivedConversationCard] =
    useState(false);
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    conversationsStored,
    clientConversationStored,
    userStatus,
    totalPagesForConversations,
    status,
    page,
    query,
    statusClientConversation,
  } = useSelector((state) => state.conversations);
  const {
    archivedConversations,
    archivedConversationsStatus,
    pageCounter,
    totalPagesForArchivedConversations,
    statusClientArchivedConversation,
  } = useSelector((state) => state.archivedConversation);
  const { messages, messagesStored } = useSelector((state) => state.messages);
  const { archivedMessagesStored, archivedMessages, archivedMessagesStatus } =
    useSelector((state) => state.archivedMessages);
  const statusMessages = useSelector((state) => state.messages.status);

  let discussions = archived
    ? archivedConversations
    : conversationsStored?.map((item) => {
        const element = userStatus?.find((el) => el?._id === item?._id);
        return { ...item, user_status: element?.status };
      });

  // Create an object to keep track of unique objects based on _id
  const uniqueObjects = {};
  // Iterate through the array
  let conversations = discussions.filter((obj) => {
    // Use the _id property as the key for the uniqueObjects dictionary
    const key = obj._id;

    // If the object with the same _id is not already in uniqueObjects, add it and return true to keep it in the result
    if (!uniqueObjects[key]) {
      uniqueObjects[key] = true;
      return true;
    }

    // If the object with the same _id is already in uniqueObjects, return false to filter it out
    return false;
  });
  choosenSegment &&
    !archived &&
    (conversations = conversations.filter(
      (conversation) =>
        conversation?.segments?.find((segment) =>
          typeof segment == "string"
            ? segment === choosenSegment?._id
            : segment?._id === choosenSegment?._id
        ) !== undefined
    ));
  assignedConversations &&
    !archived &&
    (conversations = conversations.filter(
      (conversation) => conversation?.assigned?.user?._id === user?._id
    ));
  useEffect(() => {
    const conversation = conversationsStored?.find(
      (conversation) => conversation?._id === messagesStored[0]?.conversation
    );
    conversation?.unread !== 0 &&
      conversation?.assigned?.user?._id === user?._id &&
      emitEvent("SET_SEEN", {
        webSiteId: user?.websiteID,
        conversation: messagesStored[0]?.conversation,
      });
  }, [messagesStored]);

  const fetchAllConversations = () => {
    if (query.query === "time") {
      dispatch(
        getAllConversationsByMinutes({
          websiteId: query?.websiteId,
          minutes: query.minutes,
          page: page,
        })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else if (query.query === "operator") {
      dispatch(
        getAllConversationsByOperator({
          websiteId: query?.websiteId,
          operatorId: query.operatorId,
          page: page,
        })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else if (query.query === "date") {
      dispatch(
        getAllConversationsByDate({
          websiteId: query?.websiteId,
          startDate: query.startDate,
          endDate: query.endDate,
          page: page,
        })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else if (query.query === "meta") {
      dispatch(
        getAllConversationsByMeta({
          websiteID: query?.websiteId,
          meta: query?.meta,
          page: page,
        })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else if ([1, 2, 3].includes(query.query)) {
      dispatch(
        getFilteredConversations({
          websiteId: user?.websiteID,
          key:
            query.query === 1
              ? "unread"
              : query.query === 2
              ? "unresolved"
              : "resolved",
          page,
        })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else if (query?.query === "department") {
      dispatch(
        getAllConversationsByDepartment({ websiteId: user?.websiteID, page })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else if (query?.query === "file") {
      dispatch(
        getAllConversationsByFile({ websiteId: user?.websiteID, page })
      ).then((res) => {
        !res?.error && dispatch(incrementPageForConversations());
      });
    } else {
      dispatch(getAllConversations({ websiteID: user?.websiteID, page })).then(
        (res) => {
          !res?.error && dispatch(incrementPageForConversations());
        }
      );
    }
  };
  const fetchAllArchivedConversations = () => {
    dispatch(
      getArchivedConversations({
        websiteID: user?.websiteID,
        page: pageCounter,
      })
    ).then((res) => {
      !res?.error && dispatch(incrementPageForArchivedConversations());
    });
  };
  const handleGetarchivedMessages = (id) => {
    window.history.replaceState(
      {
        ...window.history.state,
        as: `/archived/${user?.websiteID}/${id}`,
        url: `/archived/${user?.websiteID}/${id}`,
      },
      "",
      `/archived/${user?.websiteID}/${id}`
    );
    setActiveArchivedConversationCard(id);
    if (!isFound(archivedMessages, id)) {
      (async () => {
        await dispatch(
          getOneArchivedConversation({
            websiteID: user?.websiteID,
            conversationID: id,
          })
        );
        dispatch(
          getArchivedMessages({
            websiteID: user?.websiteID,
            conversationID: id,
            page: 1,
          })
        );
      })();
    } else {
      archivedMessagesStored[0]?.conversation !== id &&
        (() => {
          dispatch(storeArchivedMessages(id));
          dispatch(storeArchivedConversation(id));
        })();
    }
  };

  const handleGetMessages = (id) => {
    window.history.replaceState(
      {
        ...window.history.state,
        as: `/messaging/${user?.websiteID}/${id}`,
        url: `/messaging/${user?.websiteID}/${id}`,
      },
      "",
      `/messaging/${user?.websiteID}/${id}`
    );
    if (!isFound(messages, id)) {
      (async () => {
        setActiveConversationCard(id);
        await dispatch(
          getOneConversationByClient({
            conversationId: id,
            user,
            websiteId: user?.websiteID,
            userId: user?._id,
          })
        ).then((res) => {
          const conversation = conversations?.find((item) => item?._id === id);
          !res?.error &&
            user?.role?.code === "OPERATOR" &&
            typeof res?.payload?.data?.assigned?.user === "string" &&
            emitEvent("ASSIGNED_OPERATOR", {
              webSiteId: user?.websiteID,
              conversation: id,
              newAssignedOperator: user,
            });
          !res?.error &&
            (typeof res?.payload?.data?.assigned?.user === "string" ||
              res?.payload?.data?.assigned?.user?._id === user?._id) &&
            conversation?.unread !== 0 &&
            emitEvent("SET_UNREAD_MESSAGES", {
              conversation: conversation?._id,
              websiteID: user?.websiteID,
            });
        });
        dispatch(
          conversationMessages({
            conversationId: id,
            websiteId: user?.websiteID,
            page: 1,
          })
        );
      })();
    } else {
      messagesStored[0]?.conversation !== id &&
        (() => {
          dispatch(storeMessages(id));
          dispatch(clientConversationStoredFunction(id));
          dispatch(
            getConvByClient({
              conversationId: id,
              user,
              websiteId: user?.websiteID,
              userId: user?._id,
            })
          ).then((res) => {
            const conversation = conversations?.find(
              (item) => item?._id === id
            );
            !res?.error &&
              (typeof res?.assigned?.user === "string" ||
                res?.assigned?.user?._id === user?._id) &&
              conversation?.unread !== 0 &&
              emitEvent("SET_UNREAD_MESSAGES", {
                conversation: conversation?._id,
                websiteID: user?.websiteID,
              });
          });
        })();
    }
  };

  useEffect(() => {
    archivedMessagesStored &&
      setActiveArchivedConversationCard(
        archivedMessagesStored[0]?.conversation
      );
  }, [archivedMessagesStored]);

  useEffect(() => {
    if (clientConversationStored)
      setActiveConversationCard(clientConversationStored?._id);
  }, [clientConversationStored]);
  const cache = new CellMeasurerCache({
    defaultWidth: 100,
    minWidth: 75,
    height: 75,
    fixedHeight: false,
  });
  useEffect(() => {
    if (
      conversationsStored?.length === 0 &&
      user?.websiteID &&
      !state &&
      !archived
    ) {
      fetchAllConversations();
    }
  }, [user]);
  useEffect(() => {
    archived &&
      !Array.isArray(user) &&
      archivedConversations?.length === 0 &&
      archivedConversationsStatus === "idle" &&
      fetchAllArchivedConversations();
  }, [user]);
  const handleListScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (
      clientHeight + scrollTop >= scrollHeight &&
      scrollTop !== 0 &&
      (archived ? pageCounter : page) <=
        (archived
          ? totalPagesForArchivedConversations
          : totalPagesForConversations) &&
      (archived ? archivedConversationsStatus : status) === "succeeded"
    ) {
      archived ? fetchAllArchivedConversations() : fetchAllConversations();
    }
  };

  return (
    <div
      className="discussions-list-wrapper"
      id="scrollable-conversations"
      style={{
        overflow: "scroll",
        display: "flex",
        height: "93vh",
        flexDirection: "column",
        padding:
          archived &&
          (i18n.language === "ar" ? "5px 33px 0 0" : "5px 0 0 25px"),
        maxWidth: archived && "432px",
      }}
    >
      {!archived && (
        <div
          className="assigned-conversations-container"
          onClick={() => setAssignedConversations((prev) => !prev)}
        >
          <span>
            {assignedConversations
              ? "Show All"
              : `Show only the conversations assigned to me`}
          </span>
        </div>
      )}

      {(archived ? archivedConversationsStatus : status) === "succeeded" &&
        conversations?.length === 0 &&
        !choosenSegment && (
          <div className="no-conversations-style">
            <span>{t("No conversations")}</span>
          </div>
        )}
      {(archived ? archivedConversationsStatus : status) === "loading" ||
      (archived ? archivedConversationsStatus : status) === "idle" ? (
        data.FAKE_SKELETONS.map((_, index) => (
          <div className="discussions-skeleton" key={index}>
            <Skeleton variant="circular" width={41} height={41.22} />
            <Skeleton
              variant="rounded"
              width={archived ? 400 : 300}
              height={60}
            />
          </div>
        ))
      ) : (
        <>
          {conversations?.length === 0 && choosenSegment ? (
            <div className="no-conversations-segment-style">
              <span>
                {t("No conversations with segment")}{" "}
                <span className="color-segment">{choosenSegment?.name}</span>
              </span>
            </div>
          ) : (
            <List
              style={{ height: "93vh", width: "100%", overflow: "scroll" }}
              id="List"
              rowCount={conversations.length}
              rowHeight={80}
              height={1000}
              width={700}
              onScroll={handleListScroll}
              rowRenderer={(props) => {
                return (
                  <CellMeasurer
                    cache={cache}
                    columnIndex={0}
                    key={props.key}
                    parent={props.parent}
                    rowIndex={props.index}
                  >
                    <ConversationCard
                      item={conversations[props.index]}
                      style={props.style}
                      key={conversations[props.index]?._id}
                      user={user}
                      activeConversationCard={
                        archived
                          ? activeArchivedConversation
                          : activeConversationCard
                      }
                      onClick={() => {
                        archived
                          ? statusClientArchivedConversation !== "loading" &&
                            archivedMessagesStatus !== "loading" &&
                            handleGetarchivedMessages(
                              conversations[props.index]?._id
                            )
                          : statusClientConversation !== "loading" &&
                            statusMessages !== "loading" &&
                            handleGetMessages(conversations[props.index]?._id);
                      }}
                      archivedConversation={archived ? true : false}
                    />
                  </CellMeasurer>
                );
              }}
            />
          )}
          {(archived ? archivedConversationsStatus : status) === "pending" && (
            <div className="discussions-skeleton">
              <Skeleton variant="circular" width={41} height={41.22} />
              <Skeleton variant="rounded" width={300} height={60} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Conversations;
